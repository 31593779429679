import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

import '../../styles/Tooltip.css';

const DonutChart = ({ data, size = "large", config }) => {
    const svgRef = useRef(null);

    let width, height;
    if (size === "small") {
        width = config.container.width_small;
        height = config.container.height_small;
    } else if (size === "large") {
        width = config.container.width_large;
        height = config.container.height_large;
    } else {
        width = 800;
        height = 300;
    }
    const radius = Math.min(width, height) / 2;
    const margin = config.container.margin;;

    // We add all values together to get the total 
    const totalValues = data.slices.reduce((acc, curr) => acc + curr.value, 0);

    useEffect(() => {
        d3.select(svgRef.current).selectAll("*").remove();
        const svg = d3.select(svgRef.current)
            .attr("width", "100%")
            .attr("height", height)
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('overflow', 'visible')
            .append("g")
            .attr("transform", `translate(${(width + margin.left + margin.right) / 2}, ${(height + margin.top + margin.bottom) / 2})`);

        // Define the arc generator
        const arc = d3.arc()
            .innerRadius(radius / 2)  // Inner radius for pie (set > 0 for donut)
            .outerRadius(radius)
            .cornerRadius(10);

        // Define the pie generator
        const pie = d3.pie()
            .sort(null)
            .value(d => d.value)
            .padAngle(0.03);

        // Generate the pie chart data
        const chartData = pie(data.slices);

        let angleInterpolation = d3.interpolate(pie.startAngle()(), pie.endAngle()());

        // Tooltip
        const tooltip = d3.select('body').append('div')
            .style('position', 'absolute')
            .style('display', 'none')
            .style('background-color', config.tooltip.backgroundColor)
            .style('padding', '8px')
            .style('border-radius', config.tooltip.borderRadius)
            .style('pointer-events', 'none')
            .style('color', config.tooltip.fontColor)
            .style('text-align', 'left');

        // Create pie chart slices with animation
        svg.selectAll("path")
            .data(chartData)
            .enter()
            .append("path")
            .attr("fill", (d, i) => {
                return d.data.color;
            })
            .attr("d", arc)
            .on('mouseover', function (event, d) {
                // Move the slice away from the center
                const [x, y] = arc.centroid(d);
                d3.select(this)
                    .transition()
                    .duration(config.slices.animation_hover.duration)
                    .attr("transform", `translate(${x * config.slices.animation_hover.distance}, ${y * config.slices.animation_hover.distance})`); // Move slice 10% of its distance from the center

                // Update the polyline position dynamically
                d3.select(`.polyline-${d.data.id}-${d.index}`)
                    .transition()
                    .duration(config.polylines.animation_hover.duration)
                    .attr('stroke', config.polylines.stroke_hover)
                    .attr("points", function () {
                        const [x1, y1] = [x * (1 + config.slices.animation_hover.distance),
                        y * (1 + config.slices.animation_hover.distance)];
                        // Calculate the mid angle of the slice
                        const midAngle = (d.startAngle + d.endAngle) / 2;

                        // Determine if the label should be on the left or right side
                        const isLeftSide = midAngle > Math.PI; // If midAngle is greater than Pi, the slice is on the left side

                        // First bend point (where the line bends horizontally)
                        const bendX = (radius + 20) * Math.cos(midAngle - Math.PI / 2);
                        const bendY = (radius + 20) * Math.sin(midAngle - Math.PI / 2);

                        // Final point (horizontal line to the left or right side)
                        const endX = isLeftSide ? -(radius + 30) : (radius + 30); // Line moves to the left or right
                        const endY = bendY; // Keep the y position consistent

                        return [[x1, y1], [bendX, bendY], [endX, endY]]; // From the centroid to the outer position
                    });

                // Update the circle position dynamically
                d3.select(`.circle-${d.data.id}-${d.index}`)
                    .transition()
                    .duration(config.circles.animation_hover.duration)
                    .attr('fill', '#6572eb')
                    .attr("cx", x * (1 + config.slices.animation_hover.distance))
                    .attr("cy", y * (1 + config.slices.animation_hover.distance));

                // Make the text bold
                d3.select(`.value-${d.data.id}-${d.index}`)
                    .attr('fill', config.texts.fill_hover);

                tooltip.style('display', 'block')
                    .html(() => {
                        console.log(d)
                        return `
                        <div>
                            <span class="circle" style="background-color: ${d.color}; display: inline-block; width: 15px; height: 15px; border-radius: 50%; margin-right: 6px;"></span>
                            <b>${d.data.label}:</b> ${d.data.value}
                        </div>`;
                    })
            })
            .on('mousemove', function (event) {
                tooltip.style('top', (event.pageY - 10) + 'px')
                    .style('left', (event.pageX + 10) + 'px');
            })
            .on('mouseout', function (event, d) {
                const [x, y] = arc.centroid(d);
                // Move the slice back to its original position
                d3.select(this)
                    .transition()
                    .duration(config.slices.animation_hover.duration)
                    .attr("transform", `translate(0, 0)`); // Reset position

                // Reset the polyline position
                d3.select(`.polyline-${d.data.id}-${d.index}`)
                    .transition()
                    .duration(config.polylines.animation_hover.duration)
                    .attr("stroke", config.polylines.stroke)
                    .attr("points", function () {
                        const [x1, y1] = [x, y];
                        // Calculate the mid angle of the slice
                        const midAngle = (d.startAngle + d.endAngle) / 2;

                        // Determine if the label should be on the left or right side
                        const isLeftSide = midAngle > Math.PI; // If midAngle is greater than Pi, the slice is on the left side

                        // First bend point (where the line bends horizontally)
                        const bendX = (radius + 20) * Math.cos(midAngle - Math.PI / 2);
                        const bendY = (radius + 20) * Math.sin(midAngle - Math.PI / 2);

                        // Final point (horizontal line to the left or right side)
                        const endX = isLeftSide ? -(radius + 30) : (radius + 30); // Line moves to the left or right
                        const endY = bendY; // Keep the y position consistent

                        return [[x1, y1], [bendX, bendY], [endX, endY]]; // From the centroid to the outer position

                    });

                // Reset the circle position
                d3.select(`.circle-${d.data.id}-${d.index}`)
                    .transition()
                    .duration(config.circles.animation_hover.duration)
                    .attr("cx", x)
                    .attr("cy", y)
                    .attr('fill', config.circles.fill);

                // Reset the text to normal
                d3.select(`.value-${d.data.id}-${d.index}`)
                    .attr('text-decoration', 'none')
                    .attr('fill', config.texts.fill);

                tooltip.style('display', 'none');
            })
            .transition()
            .duration(config.slices.animation_start.duration)
            .attrTween("d", d => {
                let originalEnd = d.endAngle;
                return t => {
                    let currentAngle = angleInterpolation(t);
                    if (currentAngle < d.startAngle) {
                        return "";
                    }

                    d.endAngle = Math.min(currentAngle, originalEnd);

                    return arc(d);
                };
            });

        // Add lines leading out of each slice to the value text
        svg.selectAll("polyline")
            .data(chartData)
            .enter()
            .append("polyline")
            .attr("stroke", config.polylines.stroke)
            .attr("stroke-width", config.polylines.strokeWidth)
            .attr('pointer-events', 'none')
            .attr("fill", "none")
            .attr("class", d => `polyline-${d.data.id}-${d.index}`) // Add a class to each polyline for easy reference
            .attr("points", d => {
                // Calculate the centroid (start point of the line)
                const [x, y] = arc.centroid(d);

                // Calculate the mid angle of the slice
                const midAngle = (d.startAngle + d.endAngle) / 2;

                // Determine if the label should be on the left or right side
                const isLeftSide = midAngle > Math.PI; // If midAngle is greater than Pi, the slice is on the left side

                // First bend point (where the line bends horizontally)
                const bendX = (radius + 20) * Math.cos(midAngle - Math.PI / 2);
                const bendY = (radius + 20) * Math.sin(midAngle - Math.PI / 2);

                // Final point (horizontal line to the left or right side)
                const endX = isLeftSide ? -(radius + 30) : (radius + 30); // Line moves to the left or right
                const endY = bendY; // Keep the y position consistent

                return [[x, y], [bendX, bendY], [endX, endY]]; // From the centroid to the outer position
            })
            .style("opacity", 0) // Initially hide the lines
            .transition()
            .delay(config.polylines.animation_start.duration) // Delay matching the pie chart animation duration
            .style("opacity", 1); // Show the lines after the delay

        // Add circle to centroid of each slice
        svg.selectAll("circle")
            .data(chartData)
            .enter()
            .append("circle")
            .attr("r", 2)
            .attr("fill", config.circles.fill)
            .attr('pointer-events', 'none')
            .attr("class", d => `circle-${d.data.id}-${d.index}`)
            .attr("cx", d => arc.centroid(d)[0])
            .attr("cy", d => arc.centroid(d)[1])
            .style("opacity", 0) // Initially hide the lines
            .transition()
            .delay(config.circles.animation_start.duration) // Delay matching the pie chart animation duration
            .style("opacity", 1); // Show the lines after the delay

        // Add text labels at the end of each line
        svg.selectAll("text.value")
            .data(chartData)
            .enter()
            .append("text")
            .attr("class", d => {
                console.log(d)
                console.log(d.data.id)
                return `value-${d.data.id}-${d.index}`
            })
            .attr("transform", d => {
                // Calculate the mid angle to position the text at the end of the line
                const midAngle = (d.startAngle + d.endAngle) / 2;
                const isLeftSide = midAngle > Math.PI; // Check if the label should be on the left or right side

                // Final position of the text
                const x = isLeftSide ? -(radius + 30) - 5 : (radius + 30) + 5;
                const y = (radius + 15) * Math.sin(midAngle - Math.PI / 2); // Align y position with the bend point

                return `translate(${x},${y})`;
            })
            .attr("text-anchor", d => ((d.startAngle + d.endAngle) / 2) < Math.PI ? "start" : "end") // Align text properly
            .attr("font-size", config.texts.fontSize)
            .attr("fill", config.texts.fill)
            .text(d => `${d.data.label} | ${(d.data.value / totalValues * 100).toFixed(2)}%`) // Display the value of the slice
            .style("opacity", 0) // Initially hide the lines
            .transition()
            .delay(config.texts.animation_start.duration) // Delay matching the pie chart animation duration
            .style("opacity", 1); // Show the lines after the delay

        // Cleanup tooltip on unmount
        return () => {
            tooltip.remove();
        };

    }, [data]);

    return (
        <svg ref={svgRef}></svg>
    );
};

export default DonutChart;
