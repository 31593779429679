import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// CSS
import '../../styles/Tooltip.css';


const BarChart = ({ data, yRange = null, size = "large", config }) => {
    const svgRef = useRef();
    let width, height;
    if (size === "small") {
        width = config.container.width_small;
        height = config.container.height_small;
    } else if (size === "large") {
        width = config.container.width_large;
        height = config.container.height_large;
    } else {
        width = 800;
        height = 300;
    }
    const margin = config.container.margin;

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        // Clear previous elements
        svg.selectAll('*').remove();

        // Set up the SVG canvas dimensions
        svg.attr('width', '100%')
            .attr('height', height)
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid meet');

        // Set up scales
        const xScale = d3.scaleBand()
            .domain(data.bars.map(d => d.label))
            .range([0, width])
            .padding(0.3);

        const yScale = d3.scaleLinear()
            .domain(yRange ? yRange : [0, d3.max(data.bars, d => d.value)])
            .range([height, 0]);

        // ######## X AXIS ########
        const xAxis = d3.axisBottom(xScale);

        // add x axis to chart
        const xAxisGroup = svg.append('g')
            .attr('transform', `translate(${margin.left},${height + margin.top})`)
            .style('font-size', config.xAxis.fontSize)
            .call(xAxis)

        xAxisGroup.selectAll('.tick line')
            .style('stroke-width', config.xAxis.strokeWidth)
            .attr('stroke-linecap', 'round')
            .style('stroke', config.xAxis.strokeColor);

        xAxisGroup.select('.domain')
            .style('stroke-width', config.xAxis.strokeWidth);

        xAxisGroup.selectAll('.tick text')
            .style('fill', config.xAxis.fontColor);

        // ######## Y AXIS ########
        const yAxis = d3.axisLeft(yScale)
            .ticks(config.yAxis.ticks);

        // add y axis to chart
        const yAxisGroup = svg.append('g')
            .style('font-size', config.xAxis.fontSize)
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .call(yAxis)

        yAxisGroup.select('.domain').remove()

        yAxisGroup.selectAll('.tick line').remove()

        yAxisGroup.selectAll('text')
            .style('fill', config.xAxis.fontColor)

        // ####### GRID LINES #######
        // Add grid lines
        const gridLines = svg.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .call(
                d3.axisLeft(yScale)
                    .tickSize(-width) // Make the grid lines span the chart width
                    .tickFormat('') // Remove the text from the grid lines
                    .tickValues(yScale.ticks(config.gridlines.ticks)) // Set the number of grid lines
            )
            .style('opacity', 0.1) // Reduce the opacity of the grid lines
            .style('stroke-width', '1'); // Add a dash pattern to the grid lines

        // ####### HORIZONTAL LINE AT Y=0 ########
        // Add horizontal line to the origin for data that goes lower that 0 on the y axis
        if (yRange) {
            if (yRange[0] < 0) {
                const xOriginLine = svg.append('line')
                    .attr('stroke', '#adb5bd')
                    .attr('stroke-width', 3)
                    .attr('y1', yScale(0) + margin.top)
                    .attr('y2', yScale(0) + margin.top)
                    .attr('x1', margin.left) // Initial position (outside the view)
                    .attr('x2', width + margin.left) // Initial position (outside the view)
            }
        }

        // ####### TOOLTIP #######
        // Create tooltip
        const tooltip = d3.select('body').append('div')
            .style('position', 'absolute')
            .style('display', 'none')
            .style('background-color', config.tooltip.backgroundColor)
            .style('padding', '8px')
            .style('border-radius', config.tooltip.borderRadius)
            .style('pointer-events', 'none')
            .style('color', config.tooltip.fontColor)
            .style('text-align', 'left');

        // Bars
        svg.selectAll('.bar')
            .data(data.bars)
            .enter()
            .append('rect')
            .attr('class', (d, i) => `bar-${d.id}-${i}`)
            .attr('x', (d) => xScale(d.label) + margin.left) // Use d.category instead of categories[i]
            .attr('y', d => yScale(0) + margin.top) // Start from the bottom
            .attr('width', xScale.bandwidth())
            .attr('height', 0) // Start with height 0
            .attr('fill', (d, i) => d.color)
            .attr('rx', config.bars.ry)
            .on('mouseover', function (event, d) {
                d3.select(this).attr('opacity', config.bars.opcaity_hover);
                tooltip.style('display', 'block')
                    .html(() => {
                        return `
                        <div>
                            <span class="circle" style="background-color: ${d.color}; display: inline-block; width: 15px; height: 15px; border-radius: 50%; margin-right: 6px;"></span>
                            <b>${d.label}:</b> ${d.value}
                        </div>`;
                    })
            })
            .on('mousemove', function (event) {
                tooltip.style('top', (event.pageY - 10) + 'px')
                    .style('left', (event.pageX + 10) + 'px');
            })
            .on('mouseout', function (d) {
                d3.select(this).attr('opacity', 1);
                tooltip.style('display', 'none');
            })
            .transition() // Add transition for the animation
            .duration(1000) // Animation duration (1 second)
            .attr('y', (d) => {
                if (d.value > 0) {
                    return yScale(d.value) + margin.top
                } else {
                    return yScale(0) + margin.top
                }
            }) // Transition to final y position
            .attr('height', (d) => {
                // we handle the cases for positive values and negative values
                if (d.value > 0) {
                    return yScale(0) - yScale(d.value)
                } else {
                    return yScale(d.value) - yScale(0)
                }
            });

        // Cleanup tooltip on unmount
        return () => {
            tooltip.remove();
        };
    }, [data]);

    return <svg ref={svgRef}></svg>;
};

export default BarChart;
