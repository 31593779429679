// src/pages/AnalyticsPage.js
import React, { useState, useEffect } from 'react';
import { Form, FormControl, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FaBalanceScale, FaMoneyBillWave, FaShieldAlt, FaLandmark, FaUserShield, FaGavel, FaFireAlt, FaFistRaised, FaPrayingHands, FaBusinessTime, FaHandHoldingUsd, FaUserTie, FaChartLine } from 'react-icons/fa';
import Swal from 'sweetalert2'

import { serverURL } from '..';
import { set } from 'firebase/database';

// Graphs
import FocusedCountryMap from '../components/Graphs/FocusedCountryMap';
import KPI from '../components/Graphs/KPI';
import LineChart from '../components/Graphs/LineChart';

// configs
import LineChart_standard from '../chart_configs/LineChart_standard.json';

const AnalyticsPage = () => {
    const navigate = useNavigate();
    const [allCountries, setAllCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [input, setInput] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryInfo, setCountryInfo] = useState(null);
    const [chartData, setChartData] = useState(null);

    // Fetch all countries form the server
    useEffect(() => {
        fetch(serverURL + 'api/v1/all-countries/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setAllCountries(data.countries);
            });
    }, []);

    useEffect(() => {
        if (selectedCountry === null) return;
        fetch(serverURL + 'api/v1/country-info?country_code=' + selectedCountry?.code, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setCountryInfo(data);
            });
    }, [selectedCountry]);

    useEffect(() => {
        if (countryInfo === null) return;
        const estimates = ['Control of Corruption: Estimate', 'Government Effectiveness: Estimate', 'Political Stability and Absence of Violence/Terrorism: Estimate', 'Regulatory Quality: Estimate', 'Rule of Law: Estimate', 'Voice and Accountability: Estimate'];
        const demo_colors = ["#395C6B", " #5D80AC", "#80A4ED", "#8FB0EF", "#9EBCF0", "#BCD3F2"]
        let chartData = {
            xValues: [],
            yValues: []
        };
        let counter = 0;
        for (let key in countryInfo.wgi) {
            // check if key is part of the data we want (only for now as a demo)
            if (!estimates.includes(key)) continue;
            let newDataset = {
                label: key,
                // round data to 2 decimal places
                data: [...countryInfo.wgi[key].values].map(value => Math.round(value * 100) / 100),
                color: demo_colors[counter]
            }
            counter++;
            chartData.yValues.push(newDataset);
            chartData.xValues = countryInfo.wgi[key].years;
        }
        setChartData(chartData);

    }, [countryInfo]);

    // Function to handle user input
    const handleInputChange = (event) => {
        const searchTerm = event.target.value;
        setInput(searchTerm);

        // Filter countries based on the input
        if (searchTerm.length > 0) {
            const filtered = allCountries.filter(country =>
                country.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredCountries(filtered);
        } else {
            setFilteredCountries([]);
        }
    };

    // Function to handle country selection
    const handleCountrySelect = (country) => {
        setInput('');
        setSelectedCountry(country);
        setFilteredCountries([]);
        // Handle what happens after selecting a country (e.g., fetch data, navigate to the country's page, etc.)
    };

    // Function to handle key press (Enter key)
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && filteredCountries.length === 1) {
            setHighlightedIndex(0);
            handleCountrySelect(filteredCountries[0]); // Select the first suggested country if none are highlighted
            event.preventDefault(); // Prevent the form from submitting
        } else if (event.key === 'Enter' && filteredCountries.length > 0) {
            setHighlightedIndex(0);
            if (highlightedIndex >= 0) {
                handleCountrySelect(filteredCountries[highlightedIndex]);
            } else {
                handleCountrySelect(filteredCountries[0]); // Select the first suggested country if none are highlighted
            }
            event.preventDefault(); // Prevent the form from submitting
        } else if (event.key === 'Enter' && filteredCountries.length === 0) {
            Swal.fire({
                title: 'Error!',
                text: 'We could not find that country in our database. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            event.preventDefault(); // Prevent the form from submitting
        } else if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the form from submitting
        }
        // Arrow Down - Move highlight down
        else if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : 0
            );
            event.preventDefault();
        }
        // Arrow Up - Move highlight up
        else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredCountries.length - 1
            );
            event.preventDefault();
        } else if (event.key === 'Backspace') {
            setHighlightedIndex(0);
        }
        else if (event.key === 'Escape') {
            setFilteredCountries([]);
        }
    };

    return (
        <div className="p-5">
            <div className="">
                <h1 className='text-primary'>Analytics</h1>
                <p className='text-secondary'>Look up the analytics of any country.</p>
            </div>
            <div className="search-bar-container w-50">
                {/* Search Input */}
                <Form className="d-flex position-relative">
                    <FormControl
                        type="text"
                        placeholder="Search for a country"
                        className="me-2"
                        value={input}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        aria-label="Search"
                    />
                    {/* Dropdown Suggestions */}
                    {filteredCountries.length > 0 && (
                        <Dropdown.Menu show className="w-100" style={{ position: 'absolute', top: '100%', maxHeight: '300px', overflowY: 'scroll' }}>
                            {filteredCountries.map((country, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => handleCountrySelect(country)}
                                    className={`dropdown-item ${index === highlightedIndex ? 'active' : ''}`}
                                >
                                    {country.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    )}
                </Form>
            </div>
            <div>
                {countryInfo && (
                    <div class="container my-5 mx-auto">
                        <div class="row mt-3 rounded bg-white shadow p-5 justify-content-center">
                            <div class="col-md-6 mb-3">
                                <h1 className='text-center' style={{ fontWeight: '700' }}>{countryInfo.country}</h1>
                                <FocusedCountryMap countryName={countryInfo.country} width={600} height={400} />
                            </div>
                        </div>
                        {/* This row show general info about the country */}
                        {countryInfo.icrg[0] && (
                            <>
                                <div class="row mt-5 px-4">
                                    <div class="col-md-8">
                                        <h2 className='text-primary'>The International Country Risk Guide (ICRG)</h2>
                                        <p>These indicators are surveyed by the PRS Group and are used to assess the political, financial, and economic stability of a country.</p>
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col-md-6 mb-3">
                                        <div className='p-2 rounded h-100'>
                                            <div className="kpi-grid container">
                                                <div className="row">
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Bureaucratic Quality"
                                                            value={countryInfo.icrg[0]['bureaucratic_quality']}
                                                            unit=" / 4"
                                                            icon={<FaUserTie />}
                                                            description="A measure of the institutional strength and quality of the bureaucracy."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Corruption"
                                                            value={countryInfo.icrg[0]['corruption']}
                                                            unit=' / 6'
                                                            icon={<FaMoneyBillWave />}
                                                            description="A measure of corruption in the political system."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Democratic Accountability"
                                                            value={countryInfo.icrg[0]['democratic_accountability']}
                                                            unit=' / 6'
                                                            icon={<FaLandmark />}
                                                            description="A measure of government responsiveness to its people."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Ethnic Tensions"
                                                            value={countryInfo.icrg[0]['ethnic_tensions']}
                                                            unit=' / 6'
                                                            icon={<FaFistRaised />}
                                                            description="A measure of ethnic unity or tension."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="External Conflict"
                                                            value={countryInfo.icrg[0]['external_conflict']}
                                                            unit=' / 12'
                                                            icon={<FaFireAlt />}
                                                            description="A measure of the intensity of external conflicts."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Government Stability"
                                                            value={countryInfo.icrg[0]['government_stability']}
                                                            unit=' / 12'
                                                            icon={<FaShieldAlt />}
                                                            description="A measure of the government’s ability to remain in office."
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div className='p-2 rounded h-100 align-content-center'>
                                            <div className="kpi-grid container">
                                                <div className="row">
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Internal Conflict"
                                                            value={countryInfo.icrg[0]['internal_conflict']}
                                                            unit=' / 12'
                                                            icon={<FaUserShield />}
                                                            description="A measure of internal political conflict."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Investment Profile"
                                                            value={countryInfo.icrg[0]['investment_profile']}
                                                            unit=' / 12'
                                                            icon={<FaHandHoldingUsd />}
                                                            description="A measure of factors affecting the risk to investment."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Law and Order"
                                                            value={countryInfo.icrg[0]['law_and_order']}
                                                            unit=' / 6'
                                                            icon={<FaBalanceScale />}
                                                            description="A measure of the strength and impartiality of the legal system."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Military in Politics"
                                                            value={countryInfo.icrg[0]['military_in_politics']}
                                                            unit=' / 6'
                                                            icon={<FaGavel />}
                                                            description="A measure of military involvement in politics."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Religious Tensions"
                                                            value={countryInfo.icrg[0]['religious_tensions']}
                                                            unit=' / 6'
                                                            icon={<FaPrayingHands />}
                                                            description="A measure of religious unity or tension."
                                                        />
                                                    </div>
                                                    <div className="col-md-4 py-2">
                                                        <KPI
                                                            title="Socioeconomic Conditions"
                                                            value={countryInfo.icrg[0]['socioeconomic_conditions']}
                                                            unit=' / 12'
                                                            icon={<FaBusinessTime />}
                                                            description="A measure of social and economic conditions."
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {chartData && <>
                            <div class="row mt-3 rounded bg-white shadow p-5 ">
                                <div class="col-md-8 align-content-center">
                                    <h2 className='text-primary'>Worldwide Governance Indicators (World Bank)</h2>
                                    <p>The Worldwide Governance Indicators (WGI) project reports aggregate and individual governance indicators for over 200 countries and territories over the period 1996–2019,
                                        for six dimensions of governance: Control of Corruption, Government Effectiveness, Political Stability and Absence of Violence/Terrorism, Regulatory Quality, Rule of Law, and Voice and Accountability.
                                    </p>
                                </div>
                                <div class="col-md-12 align-content-center">
                                    <LineChart data={chartData} yRange={[-2.5, 2.5]} config={LineChart_standard} />
                                </div>
                            </div>

                        </>}
                    </div>
                )}
            </div>
        </div >
    );
};

export default AnalyticsPage;
