// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
// auth
import { AuthProvider } from './auth/AuthProvider';
// Components
import PrivateRoute from './components/Routing/PrivateRoute';
import Navbar from './components/Navigation/Navbar';
import TopBar from './components/Navigation/TopBar';
import SurveyRoutes from './components/Routing/SurveyRoutes';
// Pages
import WelcomePage from './pages/WelcomePage';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AnalyticsPage from './pages/Analytics';

// Question Data
import surveyQuestions from './data/questions.json';
import surveyAnswers from './data/answers.json';
import industryBenchmarks from './data/industryBenchmarks';
import trends from './data/trends.json';
import './App.css'; // Include CSS file for styling
// Utils
import { ComputeDashboardData } from './common/utils';
// Sample Data
import sample_company_data from './data/sample_data.json';


const App = () => {
  const user = { name: "John Doe" }; // Example user data
  const [companyData, setCompanyData] = useState(sample_company_data);
  const [dashbaordData, setDashboardData] = useState(null);
  const [dataExists, setDataExists] = useState(true);

  return (
    <Router>
      <AuthProvider>
        <div className="d-flex flex-column vh-100" data-bs-theme="light">
          <TopBar />
          <div className='d-flex flex-grow-1 overflow-auto' >
            <NavbarWrapper
              companyData={companyData}
              surveyQuestions={surveyQuestions}
              setCompanyData={setCompanyData}
              ComputeDashboardData={ComputeDashboardData(setDashboardData, setDataExists, surveyQuestions)}
              setDataExists={setDataExists}
            />
            <div className="bg-light content flex-grow-1 overflow-auto" id='content'>
              <Routes>
                <Route element={<PrivateRoute />}>
              /* TODO: curerntly the "/" page is not used, maybe there should be a section here to select a client */
                  <Route path="/" element={<WelcomePage />} />
                  <Route path="/company-data/*" element={
                    <SurveyRoutes
                      companyData={companyData}
                      surveyQuestions={surveyQuestions}
                      setCompanyData={setCompanyData}
                      ComputeDashboardData={ComputeDashboardData(setDashboardData, setDataExists, surveyQuestions)} />
                  } />
                  <Route path="/dashboard"
                    element={<Dashboard
                      user={user}
                      dataExists={dataExists}
                      dashbaordData={dashbaordData}
                      surveyQuestions={surveyQuestions}
                      trendData={trends}
                      industryBenchmarks={industryBenchmarks} />} />
                  <Route path="/analytics" element={<AnalyticsPage />} />
                </Route>
                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
          </div>
        </div>
      </AuthProvider>
    </Router >
  );
};


const NavbarWrapper = ({ companyData, setCompanyData, ComputeDashboardData, setDataExists }) => {

  const fillSampleData = () => {
    // create deep copy of sample data
    const sample_company_data_copy = JSON.parse(JSON.stringify(sample_company_data));
    setCompanyData(sample_company_data_copy)
    ComputeDashboardData(sample_company_data_copy)
    setDataExists(true);
  };

  return <Navbar companyData={companyData} fillSampleData={fillSampleData} surveyQuestions={surveyQuestions} />;
};

export default App;
