
const getColor = (risk) => {
    const r = 255; // Red component remains constant at 255
    const g = Math.round(255 - (risk * 2.55)); // Green component decreases from 255 to 0
    const b = 0; // Blue component remains constant at 0
    return `rgb(${r}, ${g}, ${b})`;
};

const ComputeDashboardData = (setDashboardData, setDataExists, surveyQuestions) => (companyData) => {
    // Compute the dashboard data from the company data
    const categoryScores = companyData.map(category => {
        const categoryTotal = category.questions.reduce((acc, question) => {
            if (question.type === "world_map") { return acc; }
            return acc + (question.answer ? Number(question.answer) : 0);
        }, 0);

        return {
            category: surveyQuestions.filter(question => question.id === category.id)[0].category,
            score: (categoryTotal / category.questions.length) * 2.5
        };

    });

    // calculate the overview KPIs that are shown in the dashboard
    const totalRiskScore = Math.round(categoryScores.reduce((acc, category) => {
        if (Array.isArray(category.score) || !Number(category.score)) {
            return acc; // Skip this item
        } else {
            return acc + category.score;
        }
    }, 0));

    const highRiskCategories = categoryScores.filter(category => category.score >= 75).length;

    let countries = companyData.filter(category => category.id === "topic4")[0].questions.filter(question => question.id === 20)[0].answer;
    const categoryScoresFiltered = categoryScores.filter(category => !Array.isArray(category.score));

    setDashboardData({
        totalRiskScore,
        highRiskCategories,
        categoryScores: categoryScoresFiltered,
        countries
    });

    setDataExists(true);

    return;
}

export { getColor, ComputeDashboardData };