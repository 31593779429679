// src/pages/WelcomePage.js
import React from 'react';
import '../styles/WelcomePage.css';

import { useNavigate, Navigate } from 'react-router-dom';

const WelcomePage = () => {
    return <Navigate to="/dashboard" replace />;

    const navigate = useNavigate();

    const handleNewAssessment = (e) => {
        e.preventDefault();
        navigate('/company-data/topic11');
    }

    return (
        <div className="welcome-page vh-100" style={{ backgroundColor: "#46556A" }}>
            <div className="welcome-container">
                <h1>Welcome to the Risk Assessment Tool</h1>
                <p>
                    Our Risk Assessment Tool helps you evaluate and manage potential risks to your company effectively.
                    With a comprehensive set of features, you can assess risks across different categories, visualize the
                    results, and take action to mitigate those risks.
                </p>
                <h2>Key Features</h2>
                <ul>
                    <li>User Authentication: Secure login system for users to access and manage their risk assessments.</li>
                    <li>Data Input Forms: Dynamic forms to collect detailed information about your company.</li>
                    <li>Risk Scoring System: Calculate risk scores based on user inputs, with a scoring range from 1 to 100.</li>
                    <li>Dynamic Risk Matrix: Interactive matrix to display risk scores across different categories.</li>
                    <li>Visualization Tools: Charts, heatmaps, and graphs to represent the risk assessment results visually.</li>
                    <li>Reporting: Generate detailed reports summarizing the risk assessment.</li>
                    <li>Customizability: Customize risk categories, scoring criteria, and visualizations.</li>
                    <li>Export Options: Export reports in PDF, Excel, and other formats.</li>
                    <li>Data Security: Ensure that user data is securely stored and complies with data protection regulations.</li>
                </ul>
                <h2>Get Started</h2>
                <p>
                    To begin, please <a href="/company-data" onClick={handleNewAssessment}>start a new risk assessment</a> or select <b>Fill Sample Data</b> in the sidebar.
                </p>
            </div>
        </div>
    );
};

export default WelcomePage;
